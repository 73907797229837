// CMS - USER MANAGEMENT
const SET_ADMIN_DATA = 'SET_ADMIN_DATA'
const SET_APP_USER_DATA = 'SET_APP_USER_DATA'
const SET_USERS = 'SET_USERS'
const SET_ADMINS = 'SET_ADMINS'
const SELECT_USER_FOR_BULK_ACTION = 'SELECT_USER_FOR_BULK_ACTION'
const DESELECT_USER_FOR_BULK_ACTION = 'DESELECT_USER_FOR_BULK_ACTION'
const CLEAR_SELECTED_USERS_FOR_BULK_ACTION = 'CLEAR_SELECTED_USERS_FOR_BULK_ACTION'
const SELECT_ADMIN_FOR_BULK_ACTION = 'SELECT_ADMIN_FOR_BULK_ACTION'
const DESELECT_ADMIN_FOR_BULK_ACTION = 'DESELECT_ADMIN_FOR_BULK_ACTION'
const CLEAR_SELECTED_ADMINS_FOR_BULK_ACTION = 'CLEAR_SELECTED_ADMINS_FOR_BULK_ACTION'
const SHOW_USER_EDIT_VIEW = 'SHOW_USER_EDIT_VIEW'
const HIDE_USER_EDIT_VIEW = 'HIDE_USER_EDIT_VIEW'
const SET_SELECTED_USER_FOR_EDITING = 'SET_SELECTED_USER_FOR_EDITING'
const SET_SELECTED_ADMIN_FOR_EDITING = 'SET_SELECTED_ADMIN_FOR_EDITING'
// ECO LAB APP
// const SAVE_WEB_MODULE = 'SAVE_WEB_MODULE'
// Web Modules
const SAVING_WEB_MODULE = 'SAVING_WEB_MODULE'
const WEB_MODULE_SAVED = 'WEB_MODULE_SAVED'
const GETTING_WEB_MODULES = 'GETTING_WEB_MODULES'
const SET_WEB_MODULES = 'SET_WEB_MODULES'
const DELETING_WEB_MODULE = 'DELETING_WEB_MODULE'
const WEB_MODULE_DELETED = 'WEB_MODULE_DELETED'
const SET_WEB_MODULE_TO_DELETE = 'SET_WEB_MODULE_TO_DELETE'
const RETRIEVING_WEB_MODULE = 'RETRIEVING_WEB_MODULE'
const WEB_MODULE_RETRIEVED = 'WEB_MODULE_RETRIEVED'
const SELECTED_WEB_MODULE_TO_EDIT = 'SELECTED_WEB_MODULE_TO_EDIT'
const UPDATING_WEB_MODULE = 'UPDATING_WEB_MODULE'
const WEB_MODULE_UPDATED = 'WEB_MODULE_UPDATED'
// Company Data
const SAVING_COMPANY_DATA_TO_DB = 'SAVING_COMPANY_DATA_TO_DB'
const COMPANY_DATA_SAVED = 'COMPANY_DATA_SAVED'
const COMPANY_DATA_ERROR = 'COMPANY_DATA_ERROR'
const GETTING_COMPANIES = 'GETTING_COMPANIES'
const SET_COMPANIES = 'SET_COMPANIES'
const DELETING_COMPANY = 'DELETING_COMPANY'
const COMPANY_DELETED = 'COMPANY_DELETED'
const SET_COMPANY_TO_DELETE = 'SET_COMPANY_TO_DELETE'
const RETRIEVING_COMPANY = 'RETRIEVING_COMPANY'
const COMPANY_RETRIEVED = 'COMPANY_RETRIEVED'
const SELECTED_COMPANY_TO_EDIT = 'SELECTED_COMPANY_TO_EDIT'
const UPDATING_COMPANY = 'UPDATING_COMPANY'
const COMPANY_UPDATED = 'COMPANY_UPDATED'

// Division, Industry, Segment Data
const GETTING_DIVISIONS = 'GETTING_DIVISIONS'
const SET_DIVISIONS = 'SET_DIVISIONS'
const GETTING_INDUSTRIES = 'GETTING_INDUSTRIES'
const SET_INDUSTRIES = 'SET_INDUSTRIES'
const GETTING_SEGMENTS = 'GETTING_SEGMENTS'
const SET_SEGMENTS = 'SET_SEGMENTS'
// Tours
const SAVING_TOUR = 'SAVING_TOUR'
const TOUR_SAVED = 'TOUR_SAVED'
const GETTING_TOURS = 'GETTING_TOURS'
const SET_TOURS = 'SET_TOURS'
const DELETING_TOUR = 'DELETING_TOUR'
const TOUR_DELETED = 'TOUR_DELETED'
const SET_TOUR_TO_DELETE = 'SET_TOUR_TO_DELETE'
const RETRIEVING_TOUR = 'RETRIEVING_TOUR'
const TOUR_RETRIEVED = 'TOUR_RETRIEVED'
const SELECTED_TOUR_TO_EDIT = 'SELECTED_TOUR_TO_EDIT'
const UPDATING_TOUR = 'UPDATING_TOUR'
const TOUR_UPDATED = 'TOUR_UPDATED'
// Custom Module
const SAVING_CUSTOM_MODULE = 'SAVING_CUSTOM_MODULE'
const CUSTOM_MODULE_SAVED = 'CUSTOM_MODULE_SAVED'
const GETTING_CUSTOM_MODULES = 'GETTING_CUSTOM_MODULES'
const RETRIEVING_CUSTOM_MODULE = 'RETRIEVING_CUSTOM_MODULE'
const SET_CUSTOM_MODULES = 'SET_CUSTOM_MODULES'
const DELETING_CUSTOM_MODULE = 'DELETING_CUSTOM_MODULE'
const CUSTOM_MODULE_DELETED = 'CUSTOM_MODULE_DELETED'
const SET_CUSTOM_MODULE_TO_DELETE = 'SET_CUSTOM_MODULE_TO_DELETE'
const SELECTED_CUSTOM_MODULE_TO_EDIT = 'SELECTED_CUSTOM_MODULE_TO_EDIT'
const UPDATING_CUSTOM_MODULE = 'UPDATING_CUSTOM_MODULE'
const CUSTOM_MODULE_UPDATED = 'CUSTOM_MODULE_UPDATED'

export {
  // CMS
  SET_USERS,
  SET_ADMINS,
  SET_ADMIN_DATA,
  SET_APP_USER_DATA,
  SELECT_USER_FOR_BULK_ACTION,
  DESELECT_USER_FOR_BULK_ACTION,
  CLEAR_SELECTED_USERS_FOR_BULK_ACTION,
  DESELECT_ADMIN_FOR_BULK_ACTION,
  SELECT_ADMIN_FOR_BULK_ACTION,
  CLEAR_SELECTED_ADMINS_FOR_BULK_ACTION,
  SHOW_USER_EDIT_VIEW,
  HIDE_USER_EDIT_VIEW,
  SET_SELECTED_USER_FOR_EDITING,
  SET_SELECTED_ADMIN_FOR_EDITING,
  // ECO LAB
  // WEB MODULE
  SAVING_WEB_MODULE,
  WEB_MODULE_SAVED,
  GETTING_WEB_MODULES,
  SET_WEB_MODULES,
  DELETING_WEB_MODULE,
  WEB_MODULE_DELETED,
  SET_WEB_MODULE_TO_DELETE,
  WEB_MODULE_RETRIEVED,
  RETRIEVING_WEB_MODULE,
  SELECTED_WEB_MODULE_TO_EDIT,
  UPDATING_WEB_MODULE,
  WEB_MODULE_UPDATED,
  // COMPANY
  SAVING_COMPANY_DATA_TO_DB,
  COMPANY_DATA_SAVED,
  COMPANY_DATA_ERROR,
  GETTING_COMPANIES,
  SET_COMPANIES, // view
  DELETING_COMPANY,
  COMPANY_DELETED,
  SET_COMPANY_TO_DELETE,
  COMPANY_RETRIEVED,
  RETRIEVING_COMPANY,
  SELECTED_COMPANY_TO_EDIT,
  UPDATING_COMPANY,
  COMPANY_UPDATED,
  // DIVISIONS, INDUSTRIES, SEGMENTS
  GETTING_DIVISIONS,
  SET_DIVISIONS,
  GETTING_INDUSTRIES,
  SET_INDUSTRIES,
  GETTING_SEGMENTS,
  SET_SEGMENTS,
  // TOUR
  SAVING_TOUR,
  TOUR_SAVED,
  GETTING_TOURS,
  SET_TOURS,
  DELETING_TOUR,
  TOUR_DELETED,
  SET_TOUR_TO_DELETE,
  TOUR_RETRIEVED,
  RETRIEVING_TOUR,
  SELECTED_TOUR_TO_EDIT,
  UPDATING_TOUR,
  TOUR_UPDATED,
  // Custom Module
  SAVING_CUSTOM_MODULE,
  CUSTOM_MODULE_SAVED,
  GETTING_CUSTOM_MODULES,
  RETRIEVING_CUSTOM_MODULE,
  SET_CUSTOM_MODULES,
  DELETING_CUSTOM_MODULE,
  CUSTOM_MODULE_DELETED,
  SET_CUSTOM_MODULE_TO_DELETE,
  SELECTED_CUSTOM_MODULE_TO_EDIT,
  UPDATING_CUSTOM_MODULE,
  CUSTOM_MODULE_UPDATED,

}
